@import "./colors";
@import "./responsive";

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: $secondary-color-1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba($secondary-color-2, 0.7);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($secondary-color-2, 0.9);
}

::-webkit-calendar-picker-indicator {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 14px;
  z-index: 1;
  font-size: 0;
  background-image: url("../images/CalendarIcon.svg");
}

.primary-text {
  color: $primary-color-1 !important;
}

.green-text {
  color: $primary-color-5 !important;
}

.black-text {
  color: $body-text !important;
}

.red-text {
  color: $danger-color-1 !important;
}

.white-text {
  color: $white !important;
}

.gray-text {
  color: #727272;
}

.gray-text-2 {
  color: $secondary-color-2;
}

.blue-text {
  color: $primary-color-2;
}

.orange-text {
  color: $primary-color-4;
}
.yellow-text {
  color: $primary-color-7;
}

.warning-text {
  color: $alert-color-2 !important;
}

.success-text {
  color: $alert-color-1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiDivider-fullWidth {
  border-color: $secondary-color-4;
}

.shadow-1 {
  box-shadow: 0px 1px 1px rgba($black, 0.15);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-breadcrumb {
  li {
    font-family: "Open Sans", sans-serif;

    .home-icon {
      display: inline-block;
      width: 30px;

      svg {
        font-size: 28px;
        color: #727272;
        width: 30px;
      }
    }

    p {
      font-family: inherit;
      font-size: 36px;
      line-height: 44px;
      font-weight: 300;
      color: $primary-color-3;
      text-transform: capitalize;
    }

    .input-block {
      color: $primary-color-3;
      font-size: 36px;
      line-height: 44px;
      font-weight: 300;
      position: relative;

      input {
        border: none;
        border-bottom: 1px dashed #666;
        width: 190px;
        padding: 0;
        margin: 0;
        color: $primary-color-3;
        font-weight: 300;

        &:focus {
          border: none;
          border-bottom: 1px dashed $primary-color-3;
          outline: none;
          background: $secondary-color-1;
        }
      }

      .validate-msg {
        position: absolute;
        left: 66px;
        line-height: normal;
        bottom: -18px;
        white-space: nowrap;
      }
    }
  }

  &__link {
    font-weight: 300;
    position: relative;
    text-transform: capitalize;

    &:hover {
      text-decoration: none;
      color: $primary-color-3;

      svg {
        color: $primary-color-3;
      }
    }

    svg {
      font-size: 30px;
      color: #727272;
    }
  }

  .MuiBreadcrumbs-separator {
    font-size: 26px;
    line-height: 44px;
    font-weight: 300;
    margin: 0 4px;
  }

  .bc-home {
    font-family: inherit;
    font-size: 36px;
    line-height: 44px;
    font-weight: 300;
    color: $primary-color-3;
    text-transform: capitalize;
  }

  @include breakpoint(sm) {
    li p {
      font-size: 26px;
      line-height: normal;
    }
  }

  @include breakpoint(vs) {
    li p {
      font-size: 20px;
      line-height: normal;
    }
  }

  &.CP {
    display: inline-block;

    li {
      svg,
      &.MuiBreadcrumbs-separator {
        // display: none;
      }
    }
  }

  &__info {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    top: 4px;
    margin-left: 8px;
    color: $primary-color-1;
    cursor: pointer;
  }
}

.inner-view {
  margin: 120px 0 0 0;

  &__title {
    border-bottom: 1px solid $secondary-color-4;
    padding-bottom: 24px;
    margin-bottom: 32px;

    svg {
      color: $primary-color-1;
      margin-left: 8px;
      font-size: 22px;
    }
  }

  &__footer {
    border-top: 1px solid $secondary-color-4;
    margin: 120px 0;
    text-align: right;

    button {
      margin-top: 40px;
    }
  }
}
.user-form-control {
  margin-bottom: 5px;
  width: 100%;
}
.custom-form-control {
  margin-bottom: 16px;
  width: 100%;

  label {
    font-family: "Open Sans", sans-serif;

    &.Mui-focused {
      color: #666;
    }
  }

  .MuiInputBase-formControl {
    font-family: "Open Sans", sans-serif;
    border-radius: 0;
    background: whitesmoke;

    &:before {
      border-color: #666;
      border-bottom-style: solid;
    }

    &:after {
      border-bottom: 1px solid #666;
    }

    &.Mui-error {
      background: rgba($danger-color-1, 0.1) !important;
    }
  }

  &.light {
    .MuiInputBase-formControl {
      background: white;
    }
  }

  &.select {
    position: relative;

    svg {
      display: none;
    }

    .MuiSelect-select,
    .MuiFormControl-fullWidth {
      position: relative;
      background: transparent;

      &:before {
        content: "";
        border: solid $primary-color-1;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        position: absolute;
        right: 16px;
        top: 24px;
        transform: rotate(45deg);
        z-index: 1;
        pointer-events: none;
      }
    }

    .MuiFormControl-fullWidth {
      input {
        padding-right: 60px;
      }

      &:before {
        right: 22px;
      }

      &:after {
        content: "";
        position: absolute;
        background: $secondary-color-4;
        right: 52px;
        top: 8px;
        z-index: 1;
        pointer-events: none;
        height: 40px;
        width: 1px;
      }
    }
  }

  &.no-border {
    .MuiFormControl-fullWidth {
      &:before {
        right: 16px;
      }

      &:after {
        display: none;
      }
    }

    .MuiInputBase-formControl {
      padding-right: 0;
    }

    .auto-complete-full {
      .MuiInputBase-formControl {
        padding-right: 0;
      }
    }
  }

  &.search {
    display: inline-flex;

    .MuiInputBase-formControl {
      padding-left: 8px;

      &:before,
      &:after {
        display: none;
      }

      input {
        height: 44px;
        padding: 0 10px 0 0;
      }

      .MuiInputAdornment-positionStart {
        margin: 0;
        border-right: 1px solid #cecece;
        height: 16px;
        margin-right: 10px;

        button {
          padding: 4px;
          margin-right: 4px;

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }

  &.date {
    input {
      text-transform: uppercase;
    }
  }

  fieldset {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  &.multi-select {
    .MuiAutocomplete-input {
      padding-right: 0 !important;
      cursor: pointer;
    }

    .Mui-focused,
    .MuiAutocomplete-hasClearIcon {
      .MuiChip-filled {
        height: 24px;
        margin: 0 3px;
      }

      .MuiInputLabel-animated {
        top: 12px;
      }

      .MuiInputBase-fullWidth {
        padding-top: 14px;
        // removed bottom padding to fix FLEX-9262 as the other controls like textbox, dropdown had 8px
        // and the 8px is being added by mui-auto-complete component anyways. so setting it to 0
        padding-bottom: 0px;
      }
    }
  }
}

.form-control__label {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;

  .selected {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
}

.custom-check {
  min-width: 56px;
  height: 56px;
  background: $secondary-color-1;
  border-radius: 0;

  &:before {
    content: "";
    border: 1px solid #727272;
    width: 24px;
    height: 24px;
    position: absolute;
    background: $white;
    opacity: 0.5;
  }

  &.Mui-checked {
    &:before {
      border-color: $primary-color-2;
    }
  }

  svg {
    position: relative;
    font-size: 16px;

    &[data-testid="CheckBoxOutlineBlankIcon"] {
      display: none;
    }
  }

  &__cont {
    .custom-check {
      padding: 0;
      min-width: 24px;
      height: 24px;
      margin-left: 8px;

      &:before {
        width: 16px;
        height: 16px;
      }
    }

    .MuiFormControlLabel-label {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: #727272;
      margin-left: 4px;
    }
  }

  &.light {
    background: $white;
    padding: 0;
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin: 0 8px 0 4px;

    .MuiTouchRipple-root {
      display: none;
    }

    & + .MuiFormControlLabel-label {
      font-size: 15px;
      line-height: 24px;
    }
  }

  &.small {
    height: 40px;
    min-width: 40px;
  }
}

.Mui-disabled {
  .custom-check:before {
    opacity: 0.2;
  }
}

.custom-form-control.select-chips {
  background: whitesmoke;

  .MuiInputBase-formControl {
    height: 60px;
    overflow: auto;
    padding: 0 !important;
    align-items: flex-start;
    margin: 30px 16px 16px 10px;
    width: auto;
    padding-right: 40px !important;

    &:before,
    &:after,
    .MuiAutocomplete-popupIndicator,
    .MuiAutocomplete-clearIndicator {
      display: none;
    }
  }

  .MuiAutocomplete-tag {
    background: white;
    border-radius: 0;
    padding: 4px 8px;
    height: 26px;
    border: none;

    input {
      padding-top: 4px;
    }

    .MuiChip-label {
      padding: 0;
      font-size: 12px;
      font-family: "Open Sans", sans-serif;
    }

    .MuiChip-deleteIcon {
      font-size: 16px;
      margin: 0;
      padding: 0;
      margin-left: 10px;
      color: #212121;
    }
  }
}

.note-text {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-top: -10px;
  line-height: 16px;
  color: #666;
}

.device-error {
  font-size: 14px;
  line-height: 22px;
  position: relative;
  padding-left: 24px;

  svg {
    font-size: 20px;
    margin-right: 6px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.error-text {
  font-size: 12px;
  line-height: 22px;
  margin-top: -10px;
  display: block;
  white-space: nowrap;

  svg {
    font-size: 16px;
    margin-right: 4px;
    vertical-align: text-top;
  }
}

.MuiAutocomplete-popper {
  font-family: "Open Sans", sans-serif;
}

.select .MuiAutocomplete-clearIndicator {
  display: none;
}

.custom-address {
  position: relative;

  ul {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 0;
    top: 56px;
    z-index: 9;
    background: white;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);

    &.active {
      display: block;
    }

    li {
      margin-bottom: 10px;
      padding: 8px 16px 0px 16px;
      cursor: pointer;

      &:hover {
        background: whitesmoke;
      }
    }
  }

  // .custom-form-control{
  //   label.Mui-error{
  //     font-size: 12px;
  //     top: -7px
  //   }
  // }
}

hr.dashed {
  border-bottom: 1px dashed $secondary-color-2;
  background: transparent;
}

.upload-file {
  background: white;
  border: 1px dashed rgba($secondary-color-2, 0.5);
  height: 144px;
  width: 440px;
  text-align: center;
  position: relative;

  p {
    display: block;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;

    .primary-btn {
      font-size: 15px;
      line-height: 1;
      font-weight: 600;
    }
  }

  img {
    max-height: 60px;
    display: none;
    margin-top: 24px;
    max-width: 100%;
    margin-bottom: 8px;

    &[src] {
      display: inline-block;

      & + svg {
        display: none;
      }
    }
  }

  &__icon {
    font-size: 40px;
    color: #666666;
    margin-top: 40px;
  }

  &__actions {
    background: #666;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;

    svg {
      cursor: pointer;
    }

    &--row {
      width: 100%;

      span {
        display: block;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      svg {
        font-size: 18px;
      }
    }
  }

  &:hover {
    .upload-file__actions {
      display: flex;
    }
  }
}

.custom-tabs {
  margin-bottom: 24px;

  .MuiTab-root {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0;
    color: #666;
    padding: 16px;
    margin-right: 16px;

    &.Mui-selected {
      color: #212121;
    }
  }

  .MuiTabs-indicator {
    background: #1464a5;
    height: 4px;
  }
}

div[role="tabpanel"] > .MuiBox-root {
  padding: 0;
}

.custom-popper {
  position: relative;

  .MuiAutocomplete-popper {
    left: 0 !important;
    top: 0 !important;
    transform: inherit !important;
    margin-top: 107px !important;
    border-radius: 0 !important;
    width: 100% !important;
    z-index: 9;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15) !important;
  }

  &.input {
    .MuiAutocomplete-popper {
      margin-top: 56px !important;
    }
  }

  .MuiPaper-rounded {
    border-radius: 0 !important;
    border: none !important;
  }
}

.toolbar-actions {
  .search {
    max-width: 400px;
    margin-bottom: 0;

    &.config-search {
      margin-bottom: 0;

      .custom-form-control {
        margin-bottom: 0;
      }

      .MuiInputBase-formControl {
        padding-top: 0;
        padding-right: 0;

        input {
          min-width: 172px;
        }
      }

      .MuiAutocomplete-endAdornment {
        display: none;
      }

      .MuiAutocomplete-root {
        &.Mui-focused {
          & + .MuiAutocomplete-popper {
            //display: none;
          }
        }
      }
    }
  }

  .primary-btn {
    height: 44px;
    padding: 0 20px;
    white-space: nowrap;
  }
}

.status-bg {
  background: #cecece;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  max-width: 155px;
  min-width: 115px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 27px;

  svg {
    width: 16px;
    margin-right: 4px;
  }

  &.success {
    background: #008800;
  }

  &.success-default {
    background: #043263;
  }

  &.warning {
    background: #ff8400;
  }

  &.error {
    background: #ff0000;
  }

  &.info {
    background: $primary-color-3;
  }

  &.info-light {
    background: $alert-color-3;
  }

  &.empty {
    background: transparent;
  }

  &.not-started {
    background: $secondary-color-4;
  }
}

.email-note {
  font-size: 12px;
  line-height: 16px;
  color: #1464a5;
  margin-top: -10px;
  display: flex;
  align-items: end;
  margin-left: 10px;
  white-space: nowrap;

  svg {
    font-size: 18px;
    margin-right: 2px;
  }
}
.custom-error-note {
  line-height: 16px;
  margin-top: -10px;
  display: flex;
  align-items: end;
  white-space: nowrap;

  svg {
    font-size: 18px;
    margin-right: 2px;
  }
}

.user-type {
  position: relative;

  svg {
    position: absolute;
    top: 18px;
    right: -30px;
  }
}

.filter-panel {
  position: absolute;
  left: 0;
  top: 188px;
  width: 100%;
  background: white;
  z-index: 8;

  .gray-bg {
    box-shadow: -1px 5px 1px rgba(0, 0, 0, 0.15);
  }
}

.filter-icon {
  position: relative;
  z-index: 10;

  &__count {
    position: absolute;
    top: -10px;
    right: -8px;
    background: #1464a5;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 10px;
    line-height: 20px;
    color: white;
    font-weight: 700;
  }
}

.rg-error {
  color: red;
  margin-left: 16px;
}

.address-box {
  padding: 16px;
  background: #f5f5f5;

  &__label {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.disable-img {
  opacity: 0.5;
  pointer-events: none;
}

.multi-cust-logo-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px 8px 16px;
  width: 184px;
  height: 72px;
}

.multi-cust-logo {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.lang-checks {
  .MuiFormControlLabel-labelPlacementEnd {
    height: 32px;
    margin-bottom: 8px;
  }
}

.categories-table {
  .custom-table th:last-child {
    width: 150px;
  }
}

.MuiTooltip-tooltip {
  font-size: 15px;
  line-height: 24px;
  padding: 32px 56px 32px 32px;
  border-radius: 0;
  background: white;
  color: #212121;
  font-weight: 400;
  box-shadow: 10px 10px 15px rgba($black, 0.1);
  max-width: 600px;
  font-family: "Open Sans", sans-serif;

  &.MuiTooltip-tooltipArrow {
    padding: 8px 12px;
    font-size: 14px;
    box-shadow: none;
    line-height: 22px;
    filter: drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.5))
      drop-shadow(0px 12px 24px rgba(145, 158, 171, 0.5));

    .MuiTooltip-arrow {
      color: $white;
    }
  }
}

#addressTooltip,
#emailTooltip {
  .MuiTooltip-tooltipPlacementBottom {
    span {
      transform: inherit !important;
      left: 30px !important;
    }
  }
}

#emailTooltip {
  .MuiTooltip-tooltipPlacementBottom {
    left: 70px;

    @include breakpoint(lg) {
      left: 106px;
    }

    @include breakpoint(vl) {
      left: 130px;
    }

    @include breakpoint(xl) {
      left: 80px;
    }

    @include breakpoint(xxl) {
      left: 100px;
    }
  }
}

#event-address {
  .MuiTooltip-tooltipPlacementBottom {
    margin: 6px 0px 0 16px;
    max-width: 300px;

    span {
      transform: inherit !important;
      left: 30px !important;
    }
  }
}

.custom-tooltip__cont {
  svg {
    vertical-align: middle;
    color: $primary-color-1;
    margin-right: 10px;
  }
}

.products-table {
  // max-height: calc(100vh - 290px);
  // overflow: auto;
}

.page-NotFound {
  text-align: center;
  padding: 104px 0;

  h2 {
    font-size: 42px;
    line-height: 58px;
    font-weight: 300;
    margin-top: 24px;

    span {
      display: block;
    }
  }

  p {
    font-size: 24px;
    line-height: 34px;
    color: #666;
    margin-top: 24px;
    font-weight: 300;
    margin-bottom: 0;

    span {
      display: block;
    }
  }

  a {
    text-decoration: none;
    background: #1464a5;
    padding: 13px 36px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin-top: 40px;
    display: inline-block;

    &:hover {
      color: white;
      opacity: 0.9;
    }
  }

  &-page {
    footer {
      display: none !important;
    }

    main {
      height: 100vh;
    }

    .main-wrapper {
      margin-top: 60px !important;
      height: calc(100vh - 60px);
      min-height: calc(100vh - 60px);
      max-width: 100%;
      padding-right: 0;
    }
  }
}

.sqlEditor {
  background-color: whitesmoke;
  padding: 32px;

  .ace_scroller {
    box-shadow: none !important;
  }
}

.sqlEditor-readOnly {
  .sqlEditor {
    height: 160px !important;
  }

  .ace_content {
    cursor: pointer;
  }

  .ace_cursor {
    display: none !important;
  }
}

.sqlEditor-edit {
  .sqlEditor {
    // height: 360px !important;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.customers-dropdown {
  max-height: calc(100vh - 390px);
  overflow: auto;
  min-height: calc(100vh - 390px);
}

.custom-pwd-field {
  width: 100%;

  .MuiInputBase-formControl {
    background: whitesmoke;
    height: 56px;
    width: 100%;
    margin-top: 0 !important;

    input {
      padding-top: 18px;
      padding-left: 13px;
      font-family: "Open Sans", sans-serif;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem whitesmoke inset;
}

.files-include-block {
  background: $secondary-color-1;
  padding: 24px;

  &__row {
    background: $white;
    margin: 0 0 10px 8px;

    label {
      width: 100%;
      margin-right: 0;

      .MuiFormControlLabel-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: calc(100% - 50px);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cont {
    min-height: 200px;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 5px;
  }
}

.update-file-view {
  .inner-view__footer {
    margin: 0 0 10px 0;
  }
}

.page-not-found {
  text-align: center;
  padding: 104px 0;

  h2 {
    font-size: 42px;
    line-height: 58px;
    font-weight: 300;
    margin-top: 24px;

    span {
      display: block;
    }
  }

  p {
    font-size: 24px;
    line-height: 34px;
    color: #666;
    margin-top: 24px;
    font-weight: 300;
    margin-bottom: 0;

    span {
      display: block;
    }
  }

  a {
    text-decoration: none;
    background: #1464a5;
    padding: 13px 36px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin-top: 40px;
    display: inline-block;

    &:hover {
      color: white;
      opacity: 0.9;
    }
  }

  &-page {
    footer {
      display: none !important;
    }

    main {
      height: 100vh;
    }

    .main-wrapper {
      margin-top: 60px !important;
      height: calc(100vh - 60px);
      min-height: calc(100vh - 60px);
      max-width: 100%;
      padding-right: 0;
    }
  }
}

.cust-name-in-grid {
  display: flex;
  align-items: center;

  .multi-cust-cname {
    max-width: calc(100% - 45px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.MuiClock-amButton span,
.MuiClock-pmButton span {
  font-weight: bold;
}
.ml-5p{
  margin-left: 4px !important;
}
.flex-loader{
  width: 100%;
}
.browse-btn{
  text-decoration: underline;
  margin-bottom: 4px;
}