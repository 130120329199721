$width-xs: 767px; // Small Mobiles
$width-vs: 1023px; // Mobiles Landscape
$width-sm: 1239px; // Tablet
$width-md: 1240px; // Tablet to laptop
$width-lg: 1366px; // desktop
$width-vl: 1440px; // desktop
$width-xl: 1600px; // 4k and above screens
$width-xxl: 1920px; // 4k and above screens

@mixin breakpoint($class) {
  @if $class ==xs {
    @media (max-width: $width-xs) {
      @content;
    }
  }

  @else if $class ==vs {
    @media (max-width: $width-vs) {
      @content;
    }
  }

  @else if $class ==sm {
    @media (max-width: $width-sm) {
      @content;
    }
  }

  @else if $class ==md {
    @media (min-width: $width-md) {
      @content;
    }
  }

  @else if $class ==lg {
    @media (min-width: $width-lg) {
      @content;
    }
  }

  @else if $class ==vl {
    @media (min-width: $width-vl) {
      @content;
    }
  }

  @else if $class ==xl {
    @media (min-width: $width-xl) {
      @content;
    }
  }

  @else if $class ==xxl {
    @media (min-width: $width-xxl) {
      @content;
    }
  }

  @else {
    //sass-lint:disable no-warn
    @warn 'Breakpoint mixin supports: xs, sm, md, lg, xl, only-xs, only-sm, only-lg';
    //sass-lint:enable no-warn
  }
}

// Custom devices
@mixin breakpoint-custom($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

/*
 Usage of breakpoints
.abc{
  padding:10px;
  @include breakpoint(sm) {
    padding: 0
  }
  @include breakpoint-custom(530) {
    padding: 0
  }
}
 */
.main-container {
  margin: auto;
  padding: 0 24px;

  @include breakpoint(md) {
    max-width: 1180px;
    padding: 0;
  }

  @include breakpoint(lg) {
    max-width: 1246px;
  }

  @include breakpoint(vl) {
    max-width: 1320px;
  }

  @include breakpoint(xl) {
    max-width: 1480px;
  }

  @include breakpoint(xxl) {
    max-width: 1760px;
  }

  @include breakpoint-custom(1921) {
    max-width: 100%;
    padding: 0 120px;
    &>.main-container {
      padding: 0;
    }
  }
}