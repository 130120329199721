@import "./colors.scss";

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-40 {
  font-size: 40px;
}
.font-90 {
  font-size: 90px;
}
.font-108 {
  font-size: 108px;
}
.f-100 {
  font-weight: 100;
}
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-nowrap {
  white-space: nowrap;
}

.ellipsis {
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
}

.ellipsis-2 {
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}
h6 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

@mixin font($font-size, $font-weight, $font-style: normal, $line-height: normal, $letter-spacing: normal, $font-family: "Open Sans") {
  font-size: $font-size * 1px;
  // font-size: $font-size * 0.1rem;
  // example using rem values and 62.5% font-size so 1rem = 10px

  font-family: $font-family !important;
  font-weight: $font-weight !important;

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: $line-height / $font-size;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing / $font-size}em;
  }
}


@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".eot");
    src: url($path+".eot?#iefix") format("embedded-opentype"), url($path+".woff")
        format("woff"), url($path+".ttf") format("truetype"), url($path+".svg##{$font-name}")
        format("svg");
    font-weight: $weight;
    font-style: $style;
  }
}