@import "./colors";
@import "./mixins";

button:focus {
  outline: none !important;
}
@mixin submit-btn {
  //size, weight, style, height, letter spacing, family
  @include font(16, 700, normal, 22, 0);
  align-self: Center;
  vertical-align: Center;
  fill: Solid;
  border-radius: 0 !important;
  text-transform: uppercase;
  cursor: pointer;
  color: $white;
  display: flex;
  align-items: center;
  text-align: center;
}
.primary-btn,
.secondary-btn {
  background: $primary-color-2;
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 50px;
  padding: 16px 32px;
  min-width: 120px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: 0.3s all;
  font-size: 15px;
  outline: none !important;
  font-family: "Open Sans", sans-serif;
  &:hover {
    opacity: 0.9;
    background: $primary-color-2;
  }
  &.MuiButton-containedSizeSmall {
    font-size: 12px;
    min-width: inherit;
    height: inherit;
    padding: 9px 12px;
    line-height: 16px;
  }
  &.Mui-disabled {
    background: #f5f5f5;
    color: #bdbdbd;
  }
  &.MuiButton-text {
    background: inherit;
    padding: 0;
    min-width: inherit;
    height: auto;
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    text-transform: inherit;
    &.cancel-btn {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
}
.secondary-btn {
  color: $primary-color-2;
  background: #f5f5f5;
  &:hover {
    opacity: 0.8;
    background: #f5f5f5;
  }
  &.MuiButton-text {
    color: #212121;
    &:hover {
      background: transparent;
    }
  }
}
.icon-btn {
  min-width: 44px;
  height: 44px;
  background: whitesmoke;
  border-radius: 0;
  color: black;
}
.toggle-buttons {
  border-radius: 0px;
  border: 1px solid $primary-color-3;
  button {
    border-radius: 0;
    border: none !important;
    margin: 1px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    padding: 8px 16px;
    &.Mui-selected {
      background: $primary-color-3 !important;
      color: $white;
    }
    &:hover {
      //background: rgba($primary-color-3, 0.8) !important;
    }
  }
  span {
    display: none !important;
  }
  
  .prvious-btn
  {float:left;
    margin-top:50px!important;
    font-weight:700!important;
    text-transform:uppercase!important;
  }
    .hdr-pd
    {
      padding:15px 20px 0;
    }
  
  



}
.prvious-btn{
  float: left;
  margin-top: 50px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}
.hdr-pd{
  padding: 15px 20px 0;
}
.login-btn.Mui-disabled {
  background: #dddddd;
}