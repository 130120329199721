@import "./colors";

.blue-bg {
  background: rgba($dashboard-color-1, 0.4);
}

.gray-bg {
  background: $secondary-color-1 !important;
}

.orange-bg {
  background: rgba($dashboard-color-3, 0.4);
}

.gray-bg-1 {
  background: #e8e8e8 !important;
}

.white-bg {
  background: $white !important;
}

.card-bg-1 {
  background: #fff6f3 !important;
}

.card-bg-2 {
  background: #fff1ce !important;
}

.card-bg-3 {
  background: #eefffe !important;
}

.card-bg-4 {
  background: #e8f8ed !important;
}

.card-bg-5 {
  background: #eaeeff !important;
}

.card-bg-6 {
  background: #f5ebff !important;
}

.card-bg-7 {
  background: #ffecf5 !important;
}

.card-bg-8 {
  background: #e3eeff !important;
}

.custom-card {
  background: #f5f5f5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 24px;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;

  &__icon {
    min-width: 128px;
    min-height: 128px;
    background: #fff6f3;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
    }
  }

  &__info {
    display: inline-flex;
    padding-left: 24px;
    flex-direction: column;

    h6 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      color: $body-text;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: #666;
      margin: 8px 0 0 0;
    }
  }
}

.custom-banner {
  background: #ebf4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 145px 40px 56px;

  h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    margin-top: 12px;
    font-weight: 400;
    color: #535353;
  }
}

.settings-card {
  min-height: 145px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px 24px 32px 24px;
  background: $secondary-color-1;

  &:hover {
    background: $secondary-color-3;
  }
}

.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.row-copy {
  position: relative;

  &__icon {
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 1;

    svg {
      font-size: 25px;
    }
  }
}

.row-paste {
  position: relative;

  &__icon {
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 6px;
    z-index: 1;

    svg {
      font-size: 25px;
    }
  }
}

.schedule-container {
  position: relative;
  z-index: 1;
}

.schedule-grid {
  background: whitesmoke;
  padding: 24px;
  align-items: flex-start;
  z-index: 1;
  justify-content: space-around;
  min-height: 190px;

  @include breakpoint(xxl) {
    justify-content: flex-start;
  }

  &__col {
    padding-right: 40px;

    &--logo {
      width: 65px;
    }

    span {
      display: block;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 12px;

      @include breakpoint(lg) {
        font-size: 13px;
        line-height: 22px;
      }

      @include breakpoint(xl) {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .qr-code {
      svg {
        max-height: 130px;
        max-width: 130px;
      }
    }
  }

  &.print-row {
    position: relative;
    z-index: -9;
    justify-content: flex-start;

    .schedule-grid__col {
      padding-right: 32px;

      span {
        font-size: 13px;
        line-height: 20px;
      }

      .font-18 {
        font-size: 15px !important;
      }

      .qr-code svg {
        max-height: 100px;
        max-width: 100px;
      }
    }
  }

  @include breakpoint(md) {
    display: grid;
    gap: 30px 0;
    grid-template-columns: 80px auto auto auto auto;
    justify-content: flex-start;

    &__col:last-child {
      grid-column-start: 2;
    }
  }

  @include breakpoint(sm) {
    display: grid;
    gap: 30px 0;
    grid-template-columns: 80px auto auto auto;
    justify-content: flex-start;

    &__col {
      &:nth-child(5) {
        grid-column-start: 2;
      }

      &:last-child {
        grid-column-start: 3;
      }
    }
  }

  @include breakpoint(lg) {
    display: flex;
    justify-content: flex-start;
  }

  @include breakpoint(xs) {
    display: flex;
    gap: 0;
    flex-direction: column;
    padding: 16px;

    &__col {
      padding-right: 40px;
      padding-bottom: 20px;
      min-width: 100%;

      span {
        margin-bottom: 8px;
        line-height: normal;
      }
    }
  }
}

.custom-accordion {
  &.dark {
    &.validates {
      border: none !important;

      .MuiAccordionSummary-gutters {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);

        &.Mui-expanded {
          box-shadow: none;
          border: none;
        }
      }

      .MuiAccordionSummary-content {
        flex-direction: column;

        .MuiFormControlLabel-label {
          font-size: 14px;
        }

        .MuiFormGroup-row {
          input {
            z-index: 0;
          }
        }
      }

      .MuiAccordionDetails-root {
        border: 1px solid #e8e8e8;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 12px;
            position: relative;
            padding-left: 20px;

            &:before {
              content: "";
              width: 6px;
              height: 6px;
              background: #00295f;
              position: absolute;
              left: 0;
              top: 9px;
              border-radius: 100px;
            }
          }
        }
      }

      h5 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }

      &.info {
        .MuiAccordionSummary-gutters {
          background: #cfe2fc;
        }
      }

      &.warning {
        .MuiAccordionSummary-gutters {
          background: #fce5ce;
        }
      }

      &.success {
        .MuiAccordionSummary-gutters {
          background: #dbebd4;
        }
      }

      &.error {
        .MuiAccordionSummary-gutters {
          background: #f0b3b3;
        }
      }
    }
  }
}

.custom-checkbox {
  position: relative;

  // margin-bottom: 70px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .checkmark {
        border-color: #1464a5;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #b8b8b8;
    z-index: 1;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid #1464a5;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 3;
      display: none;
    }
  }
}

.tag-list {
  padding: 24px 32px 24px 32px;
  align-items: center;
  justify-content: space-between;

  &__col {
    display: inline-flex;
    margin-right: 54px;
    flex-direction: column;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;

    &-title {
      line-height: 24px;
      opacity: 0.9;
    }

    &-value {
      display: block;
      margin-top: 4px;

      &.ellipsis {
        max-width: 100%;
      }
    }
  }
}

.events-list {
  max-height: calc(100vh - 405px);
  overflow: auto;

  &__col {
    width: 33.3%;

    @include breakpoint(sm) {
      width: 50%;
    }

    @include breakpoint(xl) {
      width: 25%;
    }

    @include breakpoint-custom(1921) {
      width: 20%;
    }

    &--cont {
      background: whitesmoke;
      padding: 16px 24px;

      .event-info {
        img {
          width: 40px;
          margin-right: 12px;
        }

        h6 {
          line-height: 1;
        }

        strong {
          font-weight: 600;
          font-size: 14px;
          display: block;
          line-height: 24px;
        }

        span {
          font-size: 14px;
          color: #666;
          line-height: 22px;
          display: block;
        }

        &--head {
          width: calc(100% - 52px);
        }

        &__logo {
          color: #bdbdbd;
          font-size: 40px;
          margin-right: 8px;
        }
      }

      .event-desc {
        border-top: 1px dashed #bdbdbd;
        border-bottom: 1px dashed #bdbdbd;
        padding-top: 14px;
        font-size: 14px;
        line-height: 22px;
        margin: 14px 0;
        padding-bottom: 10px;

        &--row {
          label,
          span {
            display: inline-block;
            width: 50%;
            margin-bottom: 3px;
          }

          span {
            color: #0f0f0f;
            font-weight: 600;
            text-align: right;
          }
        }
      }

      .variance-progress {
        border-top: 1px dashed #bdbdbd;
        margin-top: 5px;
        padding-top: 10px;
      }

      .audit-progress {
        min-height: 120px;
      }

      .custom-progressbar {
        width: 130px;
        display: inline-block;

        &__bar {
          height: 22px !important;
          background: white !important;
        }
      }

      .accuracy-text {
        font-size: 15px;
        font-weight: 700;
        display: inline-flex;
        align-items: start;

        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

.progress-row {
  span {
    display: inline-block;
    color: $white;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    min-height: 20px;
    line-height: 20px;
    min-width: 30px;

    &.not-completed {
      background: $secondary-color-4;
    }

    &.in-progress,
    &.completed {
      background: $primary-color-1;
    }

    &.light {
      opacity: 0.7;
    }

    &.selected {
      transform: scaleY(1.2);
    }
  }

  &.audit {
    span {
      &.in-progress,
      &.completed {
        background: $primary-color-3;
      }
    }
  }

  &.variance {
    span {
      &.in-progress,
      &.completed {
        background: $primary-color-6;
      }
    }
  }
}

.success-info {
  display: inline-flex;
  margin: 16px 0 0 8px;

  svg {
    color: $primary-color-5;
    margin-right: 4px;
  }
}

.accuracy-status {
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  &.success {
    color: $primary-color-5;
  }

  &.warning {
    color: #ff8400;
  }

  &.error {
    color: $danger-color-1;
  }
}
