@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/utilities/api";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/tables";
@import "./base";
@import "./colors";
@import "./mixins";
@import "./responsive";
@import "./typography";
@import "./buttons";
@import "./header";
@import "./cards";
@import "./dialog";
@import "./alerts";
@import "./table";
@import "./progressbar";

@import "./spinner";


@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: "Open Sans";
  src: url(../fonts/OpenSans-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

body,
.MuiTypography-root {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

