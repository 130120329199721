.custom-progressbar {
  position: relative;
  &__bar {
    height: 30px;
    background: white;
    &.not-started {
      background: gray;
    }
    &.count {
      .MuiLinearProgress-bar {
        background-color: $primary-color-1;
      }
    }
    &.audit {
      .MuiLinearProgress-bar {
        background-color: $primary-color-3;
      }
    }
    &.variance {
      .MuiLinearProgress-bar {
        background-color: $primary-color-6;
      }
    }
  }
  &__val {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    padding-right: 2px;
    min-width: 30px;
    &.val-full {
      width: 100% !important;
      text-align: center;
    }
  }
  &.in-progress {
    .custom-progressbar__bar {
      opacity: 0.7;
    }
  }
  &.not-started {
    .MuiLinearProgress-bar {
      background-color: #bdbdbd !important;
    }
  }
  &__text {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }

  &.countProgress {
    .not-started {
      .MuiLinearProgress-bar {
        background: gray;
      }
    }

    .in-progress {
      .MuiLinearProgress-bar {
        background: #043263;
      }
    }

    .completed {
      .MuiLinearProgress-bar {
        background: #043263;
      }
    }
  }

  &.auditProgress {
    .MuiLinearProgress-bar {
      background: #007aff;
    }
  }

  &.varianceProgress {
    .MuiLinearProgress-bar {
      background: #8300c4;
    }
  }

  &.teamEfficiency {
    .MuiLinearProgress-bar {
      background: #ff0000;
    }
  }
  .MuiLinearProgress-colorSuccess {
    height: 10px;
    background-color: $secondary-color-1;
  }
  .MuiLinearProgress-barColorSuccess {
    background-color: #2ebd57;
  }
}

.orange-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      background: #ff8400;
    }
  }
}
.green-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      //background: $primary-color-2 ;
      transform: none !important;
    }
  }
}
.red-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      background: $danger-color-1;
    }
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
  &:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid $primary-color-3;
    border-color: $primary-color-3 $primary-color-3 $primary-color-3 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
