@import "./colors";

.header {
  display: flex;
  justify-content: space-between;
  background: $primary-color-1;
  align-items: center;
  height: 80px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 99;

  .main-container {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  &__logo {
    display: flex;
    align-items: center;

    &--btn {
      color: white;
      margin-right: 48px;
      padding: 0;

      @include breakpoint(sm) {
        margin-right: 12px;
      }

      svg {
        font-size: 36px;
      }
    }

    &--text {
      color: white;
      font-weight: 100;
      opacity: 0.8;
      position: relative;
      font-size: 20px;
      margin-left: 16px;
      padding-left: 16px;

      &:before {
        content: "";
        width: 1px;
        background: white;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.8;
      }
    }
    img {
      @include breakpoint(vs) {
        max-width: 180px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: right;

    &--logo {
      //margin-right: 40px;
      //padding-right: 40px;
      position: relative;
      display: inline-flex;
      align-items: center;
      margin: 0 60px 0 8px;

      @include breakpoint(sm) {
        margin-right: 20px;
        padding-right: 20px;
      }

      img {
        width: 36px;
        height: 36px;
      }

      span {
        color: white;
        display: inline-block;
        font-size: 18px;
        position: relative;
        line-height: 26px;
        margin-left: 8px;
        //padding-right: 16px;
        font-weight: 500;

        &:after {
          content: "";
          border: solid white;
          border-width: 0 2px 2px 0;
          display: none;
          padding: 3px;
          position: absolute;
          right: 0;
          transform: rotate(-45deg);
          top: 10px;
          opacity: 0;
        }
      }

      &:after {
        content: "";
        width: 1px;
        height: 30px;
        background: white;
        position: absolute;
        right: 0;
        top: 10px;
        opacity: 0;
      }

      &:before {
        content: "";
        width: 1px;
        background: white;
        position: absolute;
        right: -32px;
        top: 12px;
        height: 30px;
        opacity: 0.7;
      }

      &.pointer {
        span:after {
          display: inline-block;
        }
      }
      @media (max-width: 480px) {
        display: none;
      }
    }
    &--profile {
      button {
        padding: 0;
        margin-top: 6px;
        .MuiAvatar-circular {
          font-family: inherit;
          font-size: 15px;
          font-weight: 700;
          color: #212121;
          background: #ebf4ff;
          border: 2px solid #fff;
        }
      }
    }
  }
}

.main-wrapper {
  margin-top: 196px !important;
  overflow: auto;
  padding-right: 8px;
  padding-bottom: 40px;
  // height: calc(100vh - 342px);
  // min-height: calc(100vh - 342px);
  // @include breakpoint(sm) {
  //   margin-top: 260px !important;
  //   height: calc(100vh - 400px);
  //   min-height: calc(100vh - 400px);
  // }
}

.left-nav {
  position: fixed;
  top: 0;
  background: $white;
  box-shadow: 10px 10px 15px rgba($black, 0.15);
  width: 480px;
  height: 100vh;
  overflow: auto;
  transition: 0.1s all;
  left: -1000px;
  z-index: 99;

  &.active {
    left: 0;

    & + .left-nav__overlay {
      display: block;
    }
  }

  &__header {
    padding: 40px 64px 64px 40px;
    align-items: center;
    justify-content: flex-start;
    min-width: 100%;
    display: flex;

    button {
      margin-right: 16px;
      display: inline-block;
      width: 52px;

      svg {
        color: black;
        font-size: 30px;
      }
    }
  }

  ul {
    margin: 0 104px;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 16px;

      a {
        color: $body-text;
        font-weight: 700;
        text-decoration: none;
        font-size: 20px;

        &:hover {
          color: $primary-color-1;
        }

        &.link-disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      .primary-btn {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  &__footer {
    border-top: 1px dashed rgba($black, 0.2);
    display: block;
    margin: 40px 104px 0 104px;
    padding-top: 40px;
    font-size: 15px;
    font-weight: 600;
  }
  @include breakpoint(xs) {
    width: 100%;
    &__header {
      padding: 20px;
      img {
        max-width: 200px;
      }
    }
    ul {
      margin: 0 0 0 40px;
      li a {
        font-size: 14px;
      }
    }
    &__footer {
      margin: 30px 0 0 40px;
      padding-top: 20px;
      font-size: 14px;
    }
  }
}

.left-nav__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba($black, 0.1);
  display: none;
}

.main-toolbar {
  padding: 24px 0;
  box-shadow: inset 0px -1px 0px rgba(219, 219, 219, 0.7);
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  background: $white;
  margin-bottom: 24px;
  z-index: 9;

  @include breakpoint(sm) {
    .MuiGrid-grid-sm-7,
    .MuiGrid-grid-sm-5 {
      max-width: 50%;
      min-width: 50%;
    }
  }
  @include breakpoint(md) {
    .MuiGrid-grid-sm-7,
    .MuiGrid-grid-sm-5 {
      max-width: 50%;
      min-width: 50%;
    }

    // &+.main-container {
    //   margin-top: 80px;

    //   .main-container {
    //     padding: 0;
    //   }
    // }
  }
  @include breakpoint(xs) {
    .MuiGrid-grid-sm-5 {
      display: none;
    }
    .MuiGrid-grid-sm-7 {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.check-logo {
  position: relative;
  background: $white;
  width: 160px;
  height: 120px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 16px 16px 0px;

  .MuiCheckbox-root {
    display: none;

    &.Mui-checked {
      display: block;
      position: absolute;
      right: 8px;
      top: 6px;
      padding: 0;

      svg {
        width: 20px;
        color: $secondary-color-2;
      }
    }
  }

  p {
    text-align: center;
    display: block;
    margin: 0;
  }

  img {
    display: block;
    margin: auto;
    height: 50px;
    max-width: 50px;
    margin-bottom: 8px;
  }

  span {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
  }

  small {
    display: block;
    font-size: 10px;
    line-height: 18px;
  }
}

.config-nav {
  background: #1464a5;
  position: fixed;
  z-index: 99;
  top: 80px;

  a {
    color: white;
    text-decoration: none;
    font-size: 15px;
    line-height: 24px;
    opacity: 0.6;
    margin: 12px 48px 0 0;
    display: inline-block;
    border-bottom: 4px solid transparent;
    padding-bottom: 12px;

    @include breakpoint(sm) {
      font-size: 12px;
      margin-right: 34px;
    }

    @include breakpoint(md) {
      margin-right: 34px;
    }

    @include breakpoint(lg) {
      margin-right: 40px;
    }

    &:hover {
      color: white;
      opacity: 0.8;
    }

    &.active {
      opacity: 1;
      border-color: #fff;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.config-portal {
  .header__actions--logo span:after {
    display: none;
  }

  & + .main-wrapper {
    margin-top: 240px !important;

    .main-toolbar {
      top: 128px;
    }
  }
}

.copy-icon {
  color: rgba($black, 0.75) !important;
  font-size: 26px !important;
}

.cp-child-tabs {
  // word-break: break-word;

  &.product-mode {
    justify-content: center !important;

    svg {
      display: none;
    }
  }
}

.opacity-50 {
  opacity: 0.5;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.height-20 {
  height: 20px !important;
}

.lang-dropdown {
  font-family: "Open Sans", sans-serif;
  margin-right: 24px;

  .MuiSelect-select {
    position: relative;
    padding: 0 30px 20px 0 !important;
    border: none !important;
    outline: none;
    top: 10px;

    &:after {
      content: "";
      position: absolute;
      right: 3px;
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      top: 10px;
      transform: rotate(45deg);
      pointer-events: none;
    }
  }

  fieldset {
    border: none;
    padding: 0;
  }

  .MuiSelect-icon {
    display: none;
  }

  &__list small,
  small {
    position: relative;
    padding-left: 42px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
    min-height: 32px;

    &:before {
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.English {
      &::before {
        background-image: url(../images/EnglishFlag.svg);
      }
    }

    &.French {
      &::before {
        background-image: url(../images/CanadaFlag.png);
      }
    }
    &.Spanish {
      &::before {
        background-image: url(../images/SpanishFlag.png);
        background-position: center;
      }
    }
  }
  @include breakpoint(xs) {
    display: none;
  }
}

.MuiMenu-paper ul {
  .dropdown-list.lang-dropdown__list {
    min-width: 240px;
    background: #f5f5f5;

    small {
      margin: 0;
      padding: 13px 13px 13px 48px;
      font-size: 15px;

      &:before {
        width: 24px;
        height: 24px;
        left: 13px;
        top: 12px;
      }
    }

    &.Mui-selected {
      background: white;
    }

    &[aria-selected="true"]:after {
      content: "";
      position: absolute;
      right: 24px;
      top: 16px;
      width: 8px;
      height: 15px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      transform: rotate(45deg);
      color: #008800;
    }

    &:last-child {
      padding-bottom: 20px;

      small {
        border-bottom: none;
      }
    }
  }
}

.row-hover {
  border-top: 4px solid rgba($primary-color-3, 0.5);
  border-bottom: 4px solid rgba($primary-color-3, 0.5);
}

.view-mode {
  background: $alert-light-color-5;
  padding: 16px;
  text-align: center;
  position: fixed;
  top: 131px;
  width: 100%;
  z-index: 99;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  &.event-view {
    top: 80px;
    padding: 2px;
  }

  svg {
    color: $primary-color-4;
    margin-right: 12px;
  }
}

.config-portal__view {
  & + .main-wrapper {
    margin-top: 290px !important;

    .main-toolbar {
      top: 178px;
    }
  }
}

[area-label="French"] {
  .config-nav a {
    @include breakpoint(sm) {
      margin-right: 22px;
      font-size: 11px;
    }

    @include breakpoint(md) {
      margin-right: 28px;
      font-size: 13px;
    }

    @include breakpoint(lg) {
      font-size: 14px;
    }
  }
}

.variance-batch-size {
  width: 200px;
  margin-bottom: 5px;
}


.profile-dropdown {
  .MuiMenu-paper {
    margin-top: 20px;
    ul {
      width: 280px;
      padding: 24px;
      font-size: 16px;
      li {
        &.profile-dropdown__name {
          font-weight: 600;
        }
        &.profile-dropdown__email {
          color: #666;
          font-size: 14px;
          margin-bottom: 16px;
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 32px;
          word-break: break-word;
        }
        &.profile-dropdown__logout {
          .primary-btn {
            padding: 6px 12px;
            height: auto;
            min-width: inherit;
            font-size: 12px;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}