// White, Black, Grays
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$body-text: rgb(33, 33, 33); //#212121

$primary-color-1: rgba(4, 50, 99, 1); // #043263
$primary-color-2: rgba(20, 100, 165, 1); // #1464A5
$primary-color-3: rgba(0, 122, 255, 1); // #007AFF
$primary-color-4: rgba(223, 152, 0, 1); //#DF9800;
$primary-color-5: rgb(38, 137, 13); // #26890d
$primary-color-6: rgb(131, 0, 196); // #8300C4
$primary-color-7: rgb(255, 192, 0); // #ffc000

$secondary-color-1: rgb(245, 245, 245); //#f5f5f5;
$secondary-color-2: rgb(102, 102, 102); //#666666;
$secondary-color-3: rgb(235, 244, 255); //#EBF4FF;
$secondary-color-4: rgb(189, 189, 189); //#BDBDBD;

$danger-color-1: rgb(255, 0, 0); //#FF0000;
$danger-color-2: rgba(253, 223, 223, 0.46);

//Dashboard Colors
$dashboard-color-1: rgba(222, 243, 253, 1); //#DEF3FD;
$dashboard-color-2: rgba(255, 246, 243, 1); //#FFF6F3;
$dashboard-color-3: rgba(255, 250, 238, 1); //#FFFAEE;
$dashboard-color-4: rgba(232, 248, 237, 1); //#E8F8ED;
$dashboard-color-5: rgba(235, 244, 255, 1); //#EBF4FF;
$dashboard-color-6: rgba(234, 238, 255, 1); //#EAEEFF;
$dashboard-color-7: rgba(183, 193, 236, 1); //#B7C1EC;

//Alert Dark Colors
$alert-color-1: rgba(0, 136, 0, 1); //#008800;
$alert-color-2: rgba(255, 132, 0, 1); //#FF8400;
$alert-color-3: rgba(54, 187, 251, 1); //#36BBFB;
$alert-color-4: rgba(0, 99, 65, 1); //#006341;
$alert-color-5: rgba(206, 206, 206, 1); //#CECECE;
$alert-color-6: rgb(255, 0, 0); //#FF0000;
$alert-color-7: rgba(72, 174, 100, 1); //#48AE64;
$alert-color-8: rgba(0, 41, 95, 1); //#00295F;

//Alert Light Colors
$alert-light-color-1: rgba(255, 220, 144, 1); //#FFDC90;
$alert-light-color-2: rgba(239, 248, 255, 1); //#EFF8FF;
$alert-light-color-3: rgba(218, 239, 224, 1); //#DAEFE0;
$alert-light-color-4: rgba(253, 223, 223, 1); //#FDDFDF;
$alert-light-color-5: rgba(255, 245, 223, 1); //#FFF5DF;
