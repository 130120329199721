@import "./colors";

.custom-alert {
  width: 100%;
  background: #eff8ff;
  border-radius: 0;
  box-shadow: none;
  padding: 24px;
  font-family: "Open Sans", sans-serif;
  &__title {
    font-family: inherit;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 5px 0;
    color: #121212;
  }

  .MuiAlert-icon {
    padding: 0;
    margin: 0 20px 0 0;
    svg {
      width: 28px;
      height: 28px;
      position: relative;
      top: 5px;
      color: #043263;
    }
  }

  .MuiAlert-message {
    padding: 0;
  }
  .MuiAlert-action {
    align-items: center;
    padding-right: 40px;
    button {
      background: white;
      color: #2479cd;
      min-width: 190px;
      &.Mui-disabled {
        opacity: 0.8;
        color: #bdbdbd;
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: #212121;
    & + p {
      margin-top: 16px;
    }
  }
  &.dark {
    background: #ebf4ff;
  }
  &.MuiAlert-standardWarning {
    background: #fff5df;
    .MuiAlert-icon svg {
      color: #df981a;
    }
  }
  &.MuiAlert-standardError {
    background: #fddfdf;
    .MuiAlert-icon svg {
      color: #ff0000;
    }
  }
  &.MuiAlert-standardSuccess {
    background: #daefe0;
    .MuiAlert-icon svg {
      color: #48ae64;
    }
  }
  ol {
    margin: 16px 0 0 0;
    padding: 0 0 0 16px;
  }

  ul {
    list-style: disc;
    margin: 0;
    padding: 0 0 0 20px;
  }
  &.top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin: auto;
    width: 630px;
}
}

.check-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border-bottom: 1px solid rgba($black, 0.1);
    padding: 24px 0;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
      font-size: 26px;
    }
  }
}
.inventory-banner {
  .custom-alert {
    padding: 24px 0;
  }
}

.roles-info{
  .custom-alert{
    p{
      margin-bottom: 4px;
      margin-top: 0px;
    }
  }
}
.Toastify {
  &__toast {
    margin-bottom: 0 !important;
    min-height: inherit !important;
    padding: 16px !important;
    border-radius: 0 !important;
    font-family: "Open Sans", sans-serif !important;
    color: #212121 !important;
    &-container {
      min-width: 500px;
      padding: 0 !important;
      border-radius: 0;
      top: 0 !important;
    }
    &-body {
      align-items: start !important;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      padding: 0 !important;
      margin: 0 !important;
    }

    &-icon {
      margin-right: 16px !important;
      svg {
        width: 22px;
      }
    }
    &--error {
      background: #fddfdf !important;
      .Toastify__toast-icon{
        color: red !important;
      }
    }
  }
  &__close-button {
    color: #212121 !important;
    opacity: 0.5 !important;
    margin-left: 16px;
    position: relative;
    top: -14px;
    right: -8px;
    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }
}