.custom-table {
    &__col {
      overflow: auto;
      &.update-icon-table{
        .custom-table {
          th, td {
            min-width: 150px !important;
            padding: 8px;
          }
        }
        @include breakpoint(md) {
          max-height: calc(100vh - 450px);
          min-height: calc(100vh - 450px);
        }
        @include breakpoint(vl) {
          max-height: calc(100vh - 530px);
          min-height: calc(100vh - 530px); 
        }
      }
    }
  
    td,
    th {
      font-family: inherit;
      font-size: 16px;
      padding: 12px 18px;
      border: 1px solid rgba($black, 0.08);
      line-height: 1;
      height: 58px;
      position: relative;
      word-break: break-word;
  
      .primary-text {
        color: $primary-color-1;
        font-weight: 700;
  
        a {
          text-decoration: none;
          color: $primary-color-4;
          text-transform: capitalize;
  
          &:hover {
            opacity: 0.8;
          }
        }
      }
  
      &.actions {
        img {
          max-width: 24px;
  
          &:hover {
            opacity: 0.6;
          }
  
          &.disabled {
            opacity: 0.4;
          }
        }
      }
    }
    td{
      line-height: 22px;
      p{
        margin-bottom: 0px !important;
        &+p{
          margin-top: 8px !important;
        }
      }
    }
  
    th {
      background: #f5f5f5;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
    }
  
    .strike-through td {
      text-decoration: line-through 1px red;
  
      &.dlt-txt {
        color: red;
        text-decoration: none;
      }
    }
  
    .strike-through {
      text-decoration: line-through 1px red;
    }
  
    .custom-progress {
      width: 188px;
    }
  
    &.count-list {
  
      .list-name,
      .list-desc {
        width: 20%;
      }
    }
  
    &.sticky-table {
      min-width: 1470px;
  
      td,
      th {
        &:last-child {
          width: 140px;
          background: white;
          position: sticky;
          right: 0;
          z-index: 9;
          text-align: center;
        }
      }
  
      th:last-child {
        background: #f5f5f5;
      }
    }
  
    &__sort {
      display: inline-block;
  
      &-icon {
        position: absolute;
        right: 10px;
        top: 18px;
  
        &.with-filter {
          right: 40px;
        }
      }
  
      &-icon1 {
        position: absolute;
        right: 10px;
        top: 12px;
  
      }
    }
  
    &__filter {
      position: absolute;
      right: 10px;
      top: 14px;
    }
  
    &__filter1 {
      position: absolute;
      right: 38px;
      top: 12px;
    }
  
    &-responsive {
      overflow: auto;
    }
  
    &.active {
      svg {
        color: $primary-color-1;
      }
    }
  
    .action-btns {
      svg {
        cursor: pointer;
        &.disabled{
          pointer-events: none;
          opacity: 0.5;
        }
      }
  
      .delete-icon {
        margin-right: 8px;
      }

      img[aria-label="disabled"] {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &__drag {
      display: inline-flex;
      cursor: move;
      &.disabled {
        opacity: 0.5;
        display: inline-flex;
        cursor: not-allowed;
      }
    }

    
  
  }

  .table-scrollable-container {
    // calulated 300px from .main-wrapper as it is adding padding and margin.
    // if in case they get changed in future or if we add @media queries, below also should be changed
    height: calc(100vh - 300px);
  }

  .table-last-column-sticky {
    .custom-table {
      position: relative;
      z-index: 1;
      th, td {
       &:last-child {
          background: whitesmoke;
          position: sticky;
          right: -1px;
          top: 0;
          z-index: 99;
          &::before {
            background: rgba(0,0,0,.08);
            content: "";
            width: 2px;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0;
          }
          &::after {
            background: rgba(0,0,0,.08);
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0;
          }
        }
      }
      td:last-child {
        background: white;
      }
    }
  }