@import "./colors";

.custom-dialog {
  .MuiDialog-paper {
    padding: 40px 56px 80px 56px;
    border-radius: 0;
    overflow: hidden;
    max-width: 880px;
  }

  &__header {
    flex-direction: column;

    &-close {
      text-align: right;

      button {
        font-weight: 400;
        height: auto;

        svg {
          font-size: 24px !important;
        }
      }
    }

    h2 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 700;
      margin: 0;
      padding: 0 0 16px 0;
    }
  }

  &__actions {
    margin: 40px 0 0 0;
    padding: 0;
  }

  &__cont {
    padding: 40px 0 80px 0;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    max-height: calc(100vh - 370px);
  }

  .separator {
    align-items: center;
    justify-content: center;
    display: flex;

    span {
      width: 14px;
      height: 3px;
      background: #bdbdbd;
      display: flex;
    }
  }

  .close-icon {
    font-size: 28px;
    color: black;
  }
}

.custom-input {
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 0;
    height: 56px;
  }

  .MuiInputLabel-root {
    font-family: inherit;
    top: 4px;
  }

  .MuiFilledInput-input,
  .MuiSelect-select {
    font-family: "Open Sans", sans-serif;
  }
}

.custom-select {
  position: relative;

  .MuiSelect-icon {
    display: none;
  }

  &:before {
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    position: absolute;
    transform: rotate(45deg);
    right: 20px;
    top: 22px;
  }
}

.custom-radio-group {
  label {
    span {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.MuiMenu-paper {
  border-radius: 0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  margin-top: 4px;

  ul {
    margin: 0;
    padding: 0;
    max-height: 320px;

    li {
      font-family: "Open Sans", sans-serif;

      &:hover {
        background: #ebf4ff;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &.img-list {
        background: whitesmoke;
        border-bottom: 1px solid #bdbdbd;
        padding: 12px 16px;

        img {
          max-width: 24px;
          margin-right: 12px;
        }
      }
    }

    .dropdown-list {
      padding: 0;
      font-family: "Open Sans", sans-serif;
      background: transparent;

      small {
        font-size: inherit;
        border-bottom: 1px solid lightgray;
        display: inline-block;
        width: 100%;
        padding: 13px 0;
        margin: 0 16px;
        font-weight: 400;
        color: #666;
        min-height: 52px;
        &:empty{  
          display: none;  
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover,
      &.Mui-selected {
        background: whitesmoke;
      }
    }
  }
}

.menu-dropdown {
  .MuiMenu-paper {
    ul {
      min-width: 200px;
      padding-bottom: 20px;
      padding-top: 16px;

      li {
        font-size: 15px;
        line-height: 24px;
        padding: 10px 24px 10px 24px;
        font-weight: 600;

        img,
        svg {
          width: 20px;
          margin-right: 16px;
        }
      }
    }
  }
}

.filter-menu {
  .MuiMenu-paper {
    margin-top: 0;

    ul {
      min-width: 177px;
      margin-bottom: 0;

      li {
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}

.clsInventory {
  .MuiMenu-paper {
    margin-top: 15px;
    min-width: 172px;

    ul {
      margin: 0;
      padding: 8px;
    }
  }
}

.MuiSelect-select img {
  max-width: 24px;
  margin-right: 12px;
}

.MuiAutocomplete {
  &-paper {
    border-radius: 0;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1) !important;
    font-family: "Open Sans", sans-serif;

    ul {
      max-height: 320px;
      padding: 0;

      li {
        padding: 0 16px !important;
        position: relative;
        min-height: 52px !important;
        font-size: 15px;
        font-weight: 400;
        color: #666;

        &:after {
          content: "";
          width: calc(100% - 32px);
          position: absolute;
          border-bottom: 1px solid lightgray;
          left: 16px;
          bottom: 0;
          height: 1px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}