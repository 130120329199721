@import '../../assets/scss/responsive';

.loginPage {
  height: 100vh;
  padding-top: 120px;
}
.loginBg {
  background: #def3fd;
  justify-content: center;
  display: inline-flex;
  @include breakpoint(sm) {
    max-width: 50%;
    min-width: 50%;
  }
  img {
    height: calc(100vh - 140px);
    @include breakpoint(sm) {
      min-height: calc(100vh - 140px);
      width: 100%;
    }
  }
}
.loginForm {
  background: #f5f5f5;
  padding:0 56px;
  height: 100%;
  align-items: center;
  display: inline-flex;
  width: 100%;
  @include breakpoint(sm) {
    padding:0 24px;
  }
  h2 {
    font-size: 36px;
    line-height: 44px;
    font-weight: 300;
    margin: 0 0 32px 0;
    padding: 0;
  }
}
.loginFormSec {
  @include breakpoint(sm) {
    max-width: 50%;
    min-width: 50%;
  }
}
