@import "./colors";

.drag-left-panel {
  background: $secondary-color-1;
  padding: 16px;
  width: 280px;
  height: calc(100vh - 260px);
  margin-bottom: -20px;
  overflow: auto;

  .custom-form-control.search {
    .MuiInputBase-formControl {
      background: $white;
    }
  }
}

.sideBarItem {
  padding: 8px 10px;
  background: $secondary-color-1;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
}

.page {
  flex: 1 1 auto;
  position: relative;
  padding-right: 20px;

  &:before {
    content: "";
    position: absolute;
    width: 65px; //old changes 51px
    height: calc(100% - 0px);
    background: $secondary-color-1;
    left: 0;
    top: 0px;
    z-index: 1;
    border-right: 1px solid rgba($secondary-color-4, 0.4);
  }

  .base.draggable.row {

    //old changes div
    &:nth-child(2n + 2) {
      background: $white;
    }

    &:nth-child(4n) {
      background: $secondary-color-1;
    }
  }
}

.dropZone {
  flex: 0 0 auto;
  transition: 200ms all;

  &:not(.lastDropzone):nth-of-type(1) {
    height: 20px;
  }

  
  &.horizontalDrag {
    width: 20px;
    height: auto;
  }

  &:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
  }

  &.active,
  .trashDropZone.active {
    border-bottom: 1px solid rgba($primary-color-3, 0.5);
  }
}

.lastDropzone {
  height: 400px;

  &.active {
    background: rgba($primary-color-3, 0.5);
    transition: 100ms all;
  }
}

.component {
  height: 60px;
  padding: 10px;
  background: #aaa;
}

.columns {
  display: flex;
  padding: 20px 0;
}

.columnContainer {
  display: flex;
}

.base {
  padding: 12px 0px;
  margin: 0;

  &.active.hover-top {
    border-top: 1px solid rgba($primary-color-3, 0.5);
  }

  &.active.hover-bottom {
    border-bottom: 1px solid rgba($primary-color-3, 0.5);
  }

  .base-row {
    display: flex;
    position: relative;
    z-index: 1;

    strong {
      min-width: 50px; 
      font-weight: 500;
      color: rgba($black, 0.7);
    }

    &__edit {
      position: absolute;
      z-index: 1;
      right: 40px;
      cursor: pointer;
      display: none;
    }

    &:hover {
      .base-row__edit {
        display: inline-block;
      }

      .base-row__delete {
        display: inline-block;
      }
    }

    &__delete {
      position: absolute;
      z-index: 1;
      right: 8px;
      cursor: pointer;
      display: none;
      color: $danger-color-1;
    }
  }

  .row-content {
    padding-left: 16px;
    padding-right: 10px;
    word-break: break-word;
  }

}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 260px);
  overflow: auto;
  background: $secondary-color-1;
  margin-left: 20px;
  margin-bottom: -20px;

  h6 {
    // border-bottom: 1px solid rgba($secondary-color-4, 0.4); //old changes 
    padding: 16px;
  }

  .rule-heading {//old changes 
    border-bottom: 1px solid rgba($secondary-color-4, 0.4);
  }
}

.custom-accordion {
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin: 0 0 8px 0 !important;
  position: relative;

  &:before {
    display: none;
  }

  .MuiAccordionSummary {
    &-expandIconWrapper {
      position: absolute;
      left: 20px;
      width: 16px;
      height: 10px;
      transform: inherit;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $body-text;
      }

      svg {
        display: none;
      }

      &.Mui-expanded {
        &:before {
          transform: rotate(90deg);
        }
      }
    }

    &-gutters {
      padding: 10px 16px 10px 40px;
      min-height: inherit;
    }

    &-content {
      font-size: 15px;
      font-weight: 500;
      margin: 0;
    }
  }

  &.dark {
    border: 1px solid #e8e8e8;
    border-radius: 0;
    margin-bottom: 24px !important;

    .MuiAccordionSummary {
      &-expandIconWrapper {
        left: inherit;
        right: 24px;

        &:before {
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(-45deg);
        }

        &.Mui-expanded {
          &:before {
            transform: rotate(45deg);
          }
        }
      }

      &-gutters {
        padding: 16px 24px;
        background: whitesmoke;

        &.Mui-expanded {
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 24px;
    }
  }
}

.cursor-move {
  cursor: move
}

.instruction-check-box {
  .MuiFormControlLabel-label {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  .MuiFormControlLabel-labelPlacementEnd {
    margin-bottom: 0px !important;
  }
}